import React, {createContext, useContext, useReducer} from 'react'

const initialState = {
    activeTab: 'Users',
    tabs: ['Users', 'Facilities', 'Menu3', 'Menu4', 'Menu5']
}

const admin = createContext(initialState)
const { Provider } = admin

const reducer = (state, action) => {

    switch(action.type) {
        case 'UPDATE_ADMIN_TAB':
            return { ...state, activeTab: action.activeTab }
        
        default:
            throw new Error()
    }
}
const AdminProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default AdminProvider
export const useAdmin = () => useContext(admin)
import React, { useEffect } from 'react'
import UsersScreen from './UsersScreen'
import FacilitiesScreen from './FacilitiesScreen'
import { useAdmin } from '../../contexts/AdminCtx'
import '../../css/AdminContainer.css'
import { AnimatePresence } from 'framer-motion'

const AdminContainer = () => {

    const adminCtx = useAdmin()
    const { activeTab, tabs } = adminCtx.state
    const handleMenuClick = (event) => {
        adminCtx.dispatch({type: 'UPDATE_ADMIN_TAB', activeTab: event.target.id})
    }

    useEffect(() => {
        console.log(activeTab)
    }, [activeTab])
    return (
        <div className='AdminContainer'>
            <div className='AdminTitle Title'>Admin Portal</div>
            <div className='AdminMenu'>
                {tabs.map((tab) => {
                    return (
                        <button
                            className='AdminButton Shadow'
                            onClick={(e) => handleMenuClick(e)}
                            key={tab}
                            id={tab}
                        >{tab}
                        </button>
                    )
                })}
            </div>
            <div className='AdminWindowContainer'>
                <AnimatePresence>
                    {activeTab === 'Users' && (
                        <UsersScreen key='Users' />
                    )}
                    {activeTab === 'Facilities' && (
                        <FacilitiesScreen key='Facilities' />
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default AdminContainer
// Packages
import React, { useRef, useEffect, useState, Fragment } from 'react'
import PouchDB from 'pouchdb'
import { MdPrint } from 'react-icons/md'
import { motion } from 'framer-motion'

// Contexts
import { useStore } from '../../Store'
import { useUser } from '../../contexts/UserCtx'
import { useLanguage } from '../../contexts/LanguageCtx'

// Components
import LabelPreview from './LabelPreview'
import FullPageSpinner from '../FullPageSpinner'

// Utility functions
import getLabelTemplate from '../../utils/print/getLabelTemplate'
import printLabel from '../../utils/print/printLabel'
import { addCaseToDB } from '../../utils/database/dbFunction'

// Styles
import '../../css/LabelWindow.css'

/* Component description:  
    The LabelContainer component handles the logic for displaying generated labels to the user.
    The user can select different label sizes which will update the preview automatically.
    The user can also click the Print & Upload button to print the label and add the data to the PouchDB database. 
*/
const LabelContainer = () => {

    // Refs
    const labelSizeRef = useRef()
    const printRef = useRef()

    // Context destructures
    const globalState = useStore()
    const { isDone, firstRef, rows, activeTab } = globalState.state
    const { caseID, caseCode, packDate, shortDate, quantity } = globalState.state.labelData
    const user = useUser()
    const { userName, facilityID, facilityName } = user.state
    const language = useLanguage()
    const activeLanguage = {...language.state}

    // Component state
    const [isPrinting, setIsPrinting] = useState(false)
    const [labelSize, setLabelSize] = useState('4x2')
    
    // Framer Motion animations
    const buttonHover = {
        scale: 1.05,
        transition: { duration: .25 }
    }
    const buttonTap = {
        scale: 0.95
    }

    /* async function handlePrint() 
         1st - calls getLabelTemplate function with data from input window and label size from <select> element
         2nd - calls printLabel function with generated label data, sends to Zebra Browser Print application to print
         3rd - calls addCaseToDB function, sends case data to PouchDB 
    */ 
    const handlePrint = async () => {
        try {
            setIsPrinting(true)
            const data = getLabelTemplate({ caseID, facilityID, facilityName, shortDate, quantity, caseCode }, labelSizeRef.current.value)
            if (data) {
                const printJob = await printLabel(data)
                addCaseToDB({ caseID, facilityID, userName, caseCode, facilityName, packDate, quantity, rows })
                if (printJob) {
                    setTimeout(() => {
                        setIsPrinting(false)
                        globalState.dispatch({type: 'RESET_STATE'})
                    }, 2000)
                }
            } 
        } catch (err) {
            throw err
        } finally {
            if (Object.entries(firstRef).length === 0) {
                return
            } else {
                document.getElementById(firstRef.current.id).focus()
            }
            }
        }
    
    // function handleSizeChange updates state when user selects a label size
    const handleSizeChange = (event) => {
        setLabelSize(event.target.value)
    }

    // Effect to sync label data between CouchDB and PouchDB whenever caseID updates
    useEffect(() => {

        const localDB = new PouchDB(`labels_${userName}`)
        const remoteDB = new PouchDB(`https://caselink.wishfarms.com/couchdb/labels_${userName}`)

        PouchDB.sync(localDB, remoteDB, {
            //live: true,
            //retry: true
          }).on('change', function (change) {
            // yo, something changed!
            //console.log(change)
          }).on('complete', function (info) {
            // ?
            console.log('Labels synced')
            setTimeout(() => {
                globalState.dispatch({type: 'SET_SYNC_STATUS', syncStatus: 'Synced'})
            }, 1000)
          }).on('paused', function (err) {
            // replication was paused, usually because of a lost connection
            // console.log(err)
          }).on('active', function (info) {
            // replication was resumed
            console.log(info)
            if (info.direction === 'push') {
                globalState.dispatch({type: 'SET_SYNC_STATUS', syncStatus: 'Syncing'})
            }
          }).on('error', function (err) {
            // totally unhandled error (shouldn't happen)
            console.log(err)
            globalState.dispatch({type: 'SET_SYNC_STATUS', syncStatus: 'Offline'})
          }).on('denied', function (info) {
            // ?
            console.log(info)
          })
    }, [caseID])

    return (
        <Fragment>
        {isPrinting && (
            <FullPageSpinner disabled={false} message='printing' />
        )}
        <div className='LabelContainer'>
            <motion.div 
                className='LabelMotionWrapper'
                key='Label'
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                transition={{ 
                    type: 'spring',
                    stiffness: 300,
                    damping: 50
                }}
                >
                {activeLanguage.TitleLabelWindow && activeLanguage.TitleLabelWindow.length < 15 ? (
                <div 
                    className='LabelTitle Title'
                >{activeLanguage.TitleLabelWindow}</div>
                ) : (
                <div 
                    className='LabelTitle TitleLong'
                >{activeLanguage.TitleLabelWindow}</div>
                )}
                <div className='LabelPreviewContainer' ref={printRef}>
                {isDone ? (
                    
                        <LabelPreview labelSize={labelSize} />
                    
                ): null}
                </div>
                <div className='LabelControls'>
                    <div className='LabelSize'>
                    <select 
                        className='select-css Shadow'
                        ref={labelSizeRef}
                        name='labelSize'
                        id='labelSize'
                        disabled={!isDone}
                        onChange={(e) => handleSizeChange(e)}
                    >   
                        <option value='4x2'>{`4 x 2 ${activeLanguage.LabelInches}`}</option>
                        <option value='4x5'>{`4 x 5 ${activeLanguage.LabelInches}`}</option>
                        <option value='4x6'>{`4 x 6 ${activeLanguage.LabelInches}`}</option>
                    </select>
                    </div>
                    <motion.button
                        whileHover={buttonHover} whileTap={buttonTap} 
                        className='ButtonPrint Button Shadow'
                        onClick={handlePrint}
                        disabled={!isDone}
                        ><MdPrint size='5vh' />
                        {activeTab === 'TabCase' && (activeLanguage.ButtonPrintUpload)}
                        {activeTab === 'TabHistory' && (activeLanguage.ButtonReprint)}
                    </motion.button>
                </div>
            </motion.div>  
        </div>
        </Fragment>
    )
}

export default LabelContainer
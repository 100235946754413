import PouchDB from 'pouchdb'
import pouchdbFind from 'pouchdb-find'
import axios from 'axios'
PouchDB.plugin(pouchdbFind)

const baseURL = 'https://caselink.wishfarms.com/couchdb/'

export async function getFacility(userName) {
    
    try {
        if (userName === 'admin') {
            return
        } else {
        const db = new PouchDB(`${baseURL}_users/org.couchdb.user:${userName}`)
        if (db) {
            const result = await db.info()
            if (result) {
                const companyName = await getCompanyName(result.companyID)
                return {facilityID: `${result.companyID}${result.locationID}`, facilityName: `${companyName} ${result.locationID}`}
            }
        }
    }
    } catch (err) {
        console.log(err)
    }
}
export async function getSettings(userName) {
    try {
        if (userName === 'admin') {
            return
        } else {
        const localDB = new PouchDB('settings')

            if (localDB) {
                const doc = await localDB.get(`${userName}_settings`)
                if (doc) {
                    return doc.settings
                }
            }
        }
    } catch (err) {
        console.log(err)
        const sync = await syncSettings(userName)
        if (sync) {
            getSettings(userName)
        }
    }
}

export async function updateSettings(userName, settings) {
    try {
        if (userName === 'admin') {
            return
        } else {
            const localDB = new PouchDB('settings')
            const localData = await localDB.get(`${userName}_settings`)

            if (localData) {
                console.log('Updating local settings')
                return await localDB.put({
                    "_id": `${userName}_settings`,
                    "_rev": localData._rev,
                    "settings": settings
                })
            }
        }
    } catch (err) {
        console.log(err)
        return err
    }
}

export async function syncSettings(userName) {
    
    try {
        if (userName === 'admin') {
            return
        } else {
        console.log('SYNCING SETTINGS')
        const remoteDB = new PouchDB(`${baseURL}_users/org.couchdb.user:${userName}`)
        const localDB = new PouchDB('settings')

        if (remoteDB && localDB) {
            const result = await remoteDB.info()
            if (result) {
                //console.log(result.settings)
                const localData = await localDB.get(`${userName}_settings`)
                    if (!localData) {
                        console.log('Adding local settings')
                        return await localDB.put({
                            "_id": `${userName}_settings`,
                            "_rev": localData._rev,
                            "settings": result.settings
                        })
                    } else {
                        const { settings } = result
                        const update = await axios.post('https://caselink.wishfarms.com/api/user', { userName, settings})
                        if (update) {
                            console.log('Remote settings updated')
                        } else {
                            console.log('Remote server unavailable')
                        }
                        
                    }
            }
        }
    }
    } catch (err) {
        console.log(err)

        const remoteDB = new PouchDB(`${baseURL}_users/org.couchdb.user:${userName}`)
        const localDB = new PouchDB('settings')

        if (remoteDB && localDB) {
            const result = await remoteDB.info()
            if (result) {
                console.log('Adding local settings')
                return await localDB.put({
                    "_id": `${userName}_settings`,
                    "settings": result.settings
                })
            } else {
                console.log('Adding default local settings')
                return await localDB.put({
                    "_id": `${userName}_settings`,
                    "settings": {"language": "English"}
                })
            }
        }
    }
}

export async function getCompanyName(companyID) {

    try {
        const db = new PouchDB(`facilities`)
        const index = await db.createIndex({
            index: {fields: ['_id', 'companyID']}
        })
        if (index) {
            const results = await db.find({
                selector: {
                    companyID: companyID
                },
                fields: ['_id']
            })
            return results.docs[0]._id
        }
    } catch (err) {
        console.log(err)
    }
}

export async function getHistory(userName, offset) {
    
    try {
        const db = new PouchDB(`labels_${userName}`)
        const index = await db.createIndex({
            index: {fields: ['date', 'user']}
        })

        if (index) {
            const results = await db.find({
                selector: {
                    user: userName,
                    date: {$gte: null},
                },
                sort: [{date: 'desc'}],
                limit: 10,
                skip: offset
            })
            return results.docs
        }
    } catch (err) {
        console.log(err)
    }
}

export async function addCaseToDB(caseData) {

    try {
        const { caseID, facilityID, facilityName, userName, packDate, quantity, rows } = caseData

        const db = new PouchDB(`labels_${userName}`)
        if (db) {
            const doc = {
                '_id': caseID,
                'type': 'label',
                'facilityID': facilityID,
                'facilityName': facilityName,
                'user': userName,
                'date': packDate,
                'quantity': quantity,
                'rows': rows
            }
            await db.put(doc)
        }

    } catch (err) {
        console.log(err.response)
    }
}

export async function getUsers() {
    try {
        const db = new PouchDB(`${baseURL}_users`)

        const results = await db.find({
            selector: {
                type: 'user'
            },
            fields: ['name', 'facilityID', 'facilityName']
        })
        return results.docs
    } catch (err) {
        console.log(err)
    }
}

export async function syncFacilities() {
    try {
        console.log('Syncing facility data')
        const localDB = new PouchDB('facilities')
        const remoteDB = new PouchDB(`${baseURL}facilities`)
        PouchDB.sync(localDB, remoteDB, {
            //live: true,
            //retry: true
          }).on('change', function (change) {
            // yo, something changed!
            //console.log(change)
          }).on('complete', function (info) {
            // ?
            console.log('Facilities synced')
            
          }).on('paused', function (err) {
            // replication was paused, usually because of a lost connection
            // console.log(err)
          }).on('active', function (info) {
            // replication was resumed
            console.log(info)
            if (info.direction === 'push') {
                
            }
          }).on('error', function (err) {
            // totally unhandled error (shouldn't happen)
            console.log(err)
            
          }).on('denied', function (info) {
            // ?
            console.log(info)
          })
    } catch (err) {
        console.log(err)
    }
}

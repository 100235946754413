import React from 'react'
import { motion } from 'framer-motion'

const FacilitiesScreen = () => {

    return (
        <motion.div 
            className='FacilityScreenContainer AdminWindow'
            initial={{x: '200%'}}
            animate={{x: 0}}
            exit={{scale: 0, opacity: 0}}
            transition={{ duration: .5 }}
            >
            <div className='FacilityScreenTitle AdminScreenTitle'>
                Facility Maintenance
            </div>
            <div className='FacilityScreenWindow'>
                Window
            </div>
        </motion.div>
    )
}

export default FacilitiesScreen
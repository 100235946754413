import React, { useState, useEffect } from 'react'
import { getUsers } from '../../utils/database/dbFunction'
import { format } from 'date-fns'

const UsersTable = (props) => {

    const [rows, setRows] = useState([])

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await getUsers()
                if (result) {
                    setRows(result)
                    //setRowTotal(result.length)
                    console.log(result)
                }
                
            } catch (err) {
                console.log(err)
                return
            } 
        }
        getData()
    }, [])

    return (
        <div className='UsersTable'>
            <table id='UsersTable' style={{width: '100%', cellPadding: '1rem'}}>
                <thead>
                    <tr>
                    <th style={{width: '25%'}}>User</th>
                    <th style={{width: '30%'}}>Facility</th>
                    <th style={{width: '10%'}}>Fac ID</th>
                    <th className='thNoBorder' style={{width: '40%'}}></th>
                    </tr>
                </thead>
                <tbody>
                 
                {rows.length > 0 && rows.map((row, index) => 

                    (
                        <tr key={index.toString()} style={index % 2 ? {background: '#dddddd'} : {background: 'white'} }>
                            <td>{row.name}</td>
                            <td>{row.facilityName}</td>
                            <td>{row.facilityID}</td>
                        </tr>
                    )
                    )}
                
                </tbody>
            </table>
        </div>
    )
}

export default UsersTable
import axios from 'axios'

export const couchAuth = async (props) => {
    try {
        const { username, password } = props
        const response = await axios({
            method: 'post',
            baseURL: 'https://caselink.wishfarms.com/couchdb/',
            url: '_session/',
            headers: {'Content-Type': 'application/json'},
            data: {name: username, password: password},
            withCredentials: true,
            })

        if (response) {
            //console.log(response)
            return response
        }
    } catch (err) {
        console.log(err)
        if (err.response) {
            console.log(err.response)
            return {status: err.response.status, statusText: err.response.statusText}
        }
    }
}

export const readCookie = async () => {
    try {
        const response = await axios({
            method: 'get',
            baseURL: 'https://caselink.wishfarms.com/couchdb/',
            url: '_session/',
            withCredentials: true
            })

        if (response) {
            return response
        }
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}

export const clearCookie = async () => {
    try {
        const response = await axios({
            method: 'delete',
            baseURL: 'https://caselink.wishfarms.com/couchdb/',
            url: '_session/',
            withCredentials: true
        })
        
        if (response) {
            return response
        }
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}

